import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import SidebarOne from "./sidebar1";
import { withRouter } from "./withRouter";

function EditTheatrefacility(props) {
  const [theatre_id, setTheatreId] = useState("");
  const [facility_name, setFacilityName] = useState("");
  const [facility_icon, setFacilityIcon] = useState(null);
  const [errors, setErrors] = useState({});
  const [theatres, setTheatres] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();

  const api_url = localStorage.getItem("api_url");
  const admin_id = localStorage.getItem("admin_id");
  const id = localStorage.getItem("id"); // Facility ID to edit

  useEffect(() => {
    // Fetch theatre list
    axios.get(api_url + "/getTheatres/"+admin_id)
      .then((response) => {
        setTheatres(response.data.Theatres_details);
      })
      .catch((error) => {
        console.error("Error fetching theatres:", error);
      });

    // Fetch facility details if in edit mode
    if (id) {
      axios.get(api_url + `/getTheatrefacility/${id}`)
        .then((response) => {
          const facility = response.data.addon;
          setTheatreId(facility.theatre_id);
          setFacilityName(facility.facility_name);
          setFacilityIcon(facility.facility_icon);

          setIsEditMode(true);
        })
        .catch((error) => {
          console.error("Error fetching facility:", error);
        });
    }
  }, [api_url, id]);

  const validateInputs = () => {
    const errors = {};
    if (!theatre_id) {
      errors.theatre_id = "Theatre selection is required";
    }
    if (!facility_name) {
      errors.facility_name = "Facility name is required";
    }
    if (!isEditMode && !facility_icon) {
      errors.facility_icon = "Facility icon is required";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("theatre_id", theatre_id);
    formData.append("facility_name", facility_name);
    if (facility_icon) {
      formData.append("facility_icon", facility_icon);
    }

    const request = isEditMode
      ? axios.put(api_url + `/updateTheatrefacility/${id}`, formData)
      : axios.post(api_url + "/addTheatreFacilities", formData);

    request
      .then((res) => {
        alert(isEditMode ? "Facility updated successfully" : "Facility added successfully");
        navigate("/Theatrefacilities", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving facility:", error);
      });
  };

  const handleFileChange = (e) => {
    setFacilityIcon(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: "flex" }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: "flex" }}>
          <p className="p_name">{isEditMode ? "Edit Theatre Facility" : "Add Theatre Facility"}</p>
        </div>
        <hr />
        <div className="product_detail">
          <div className="item_details">
            <p className="item_name">Select Theatre</p>
            <select
              className="item_input_cat"
              value={theatre_id}
              onChange={(e) => setTheatreId(e.target.value)}
            >
              {theatres.map((theatre) => (
                <option key={theatre._id} value={theatre._id}>
                  {theatre.theatre_name}
                </option>
              ))}
            </select>
            {errors.theatre_id && <Alert severity="error">{errors.theatre_id}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <p className="item_name">Facility Name</p>
            <input
              type="text"
              className="item_input_cat"
              value={facility_name}
              onChange={(e) => setFacilityName(e.target.value)}
            />
            {errors.facility_name && <Alert severity="error">{errors.facility_name}</Alert>}
          </div>
          <br /> 
          <div className="item_details">
            <p className="item_name">Facility Icon</p>
            <input type="file" onChange={handleFileChange} />
            {facility_icon && <img src={facility_icon} alt="Uploaded File" />}
            {errors.facility_icon && <Alert severity="error">{errors.facility_icon}</Alert>}
          </div>
          <br />
          <button className="itme_img11" onClick={handleSubmit}>
            {isEditMode ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditTheatrefacility);
