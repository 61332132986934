import React from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Link, NavLink } from 'react-router-dom';
import '../assets/css/dashboard.css';
import axios from 'axios';
import SidebarOne from "../components/sidebar1";
import InnerHeader from "../components/InnerHeader";
import CanvasJS from '@canvasjs/charts';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    api_url:localStorage.getItem('api_url'),
    data:[], 
    data1:[],
    data2:[],
    data3:[],
    data4:[],
    data5:[],
    data6:[]           
  };     
  
}
componentDidMount() {   
  



  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsuser',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data1: data1.counts1,             
          });
  });

  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsMovies',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data2: data1.counts1,             
          });
  });


  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsEvents',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data3: data1.counts1,             
          });
  });

  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsSports',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data4: data1.counts1,             
          });
  });

  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsBookings',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data5: data1.counts1,             
          });
  });

  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsincome',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data6: data1.counts1,             
          });
  });

}

renderChart() {
  var chart = new CanvasJS.Chart("chartContainer", {
    title: {
      text: "Basic Column Chart in JavaScript"
    },
    data: [{
      type: "column",
      dataPoints: [
        { label: "apple", y: 10 },
        { label: "orange", y: 15 },
        { label: "banana", y: 25 },
        { label: "mango", y: 30 },
        { label: "grape", y: 28 }
      ]
    }]
  });

  // Render Chart
  chart.render();
}

render() {
  return (
    <div className="dashboard">
          <SidebarOne />
        <InnerHeader />
        <hr></hr>
        <div className='dashboard_hash' style={{'margin-left':'20%','backgroundColor':'#F9F9FC'}}>
              <p>Dashboard</p>
<br></br>
              <div className='dashboard_layouts' >
                    <div className="col-md-6">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">User Management</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data1.customerc}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            <div class="col-md-4 lineimg">
                            <div className="sales-today-copy-5">{this.state.data1.customerc}</div>
                            <div className="sales-today-3 act">Active</div>
                            </div>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">0</div>
                            <div className="sales-today-3 iact">Inactive</div>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">Movies</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data2.movies}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            <div class="col-md-4 lineimg">
                            <div className="sales-today-copy-5">{this.state.data2.movies}</div>
                            <div className="sales-today-3 act">Active</div>
                            </div>
                        </div>
                      </div>
                    </div>

                    </div>
<br></br>
                    <div className='dashboard_layouts' >
                    <div className="col-md-6">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">Events</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data3.events}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            <div class="col-md-4 lineimg">
                            <div className="sales-today-copy-5">{this.state.data3.events}</div>
                            <div className="sales-today-3 act">Active</div>
                            </div>
                            
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">Sports</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data4.sports}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            <div class="col-md-4 lineimg">
                            <div className="sales-today-copy-5">{this.state.data4.sports}</div>
                            <div className="sales-today-3 act">Active</div>
                            </div>
                          
                        </div>
                      </div>
                    </div>
                    </div>
                    <br></br>
                    <div className='dashboard_layouts' >
                    <div className="col-md-6">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">Total Income</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data6.totalAmount}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">Total Bookings</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data5.bookings}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            
                           
                        </div>
                      </div>
                    </div>

                   </div> 
                   <br></br>


              </div>

      
                
        </div>
  );
}

}

export default Dashboard;



